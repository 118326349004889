import { BaseGridControl } from 'o365.controls.Grid.BaseGrid.ts';
import BaseGridExtension from 'o365.controls.Grid.BaseExtension.ts';

//--------------------------------
// TODO: move from file
import FilterObject from 'o365.modules.FilterObject.ts';
import { applyFilterObject } from 'o365.modules.filterUtils.ts';
//--------------------------------
import { watch, ref, computed } from 'vue';

export function useBaseFilter(options: {
    dataRef: { value: any[] };
    gridControl: BaseGridControl;
}) {
    BaseGridFilter.extend(options.gridControl);
    const resultData = ref([...options.dataRef.value]);
    const sortedArray = computed(() => {
        return [...options.dataRef.value].sort();
    });

    const applyFilter = async () => {
        resultData.value = applyFilterObject(options.dataRef.value, options.gridControl.filter.filterObject.filterObject);
        if (options.gridControl.arrayData) {
            options.gridControl.arrayData.filteredRowCount = resultData.value.length;
        }
    };

    watch(sortedArray, () => {
        applyFilter();
    });

    options.gridControl.filter.filterObject.dataObject.load = applyFilter;

    return {
        filteredData: computed(() => resultData.value),
        applyFilter,
    };
}


declare module 'o365.controls.Grid.BaseGrid.ts' {
    interface BaseGridControl {
        filter: BaseGridFilter;
    }
}

export default class BaseGridFilter extends BaseGridExtension {
    static __extension = 'filter';
    
    filterObject: FilterObject;

    initializeExtension() {
        const gridControl = this._getGridControl();
        this.filterObject = new FilterObject({
            dataObject: {
                //id: 'string',
                recordSource: {
                    filterString: null,
                    clearFilter() { this.filterString = null; }
                },
                load: () => { console.warn('Filter composable not initated'); },
                setIndexForFiltersList: () => { console.warn('FilterList not implemented for array data') },
            },
            columns: gridControl.columns.columns,
        });
    }

}
